import './styles/index.css';

import { Box, Stack } from '@chakra-ui/react';
import { Link, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { LeadForm } from '../../../components/Forms';
import { LeadFormMode } from '../../../components/Forms/LeadGen/types';
import Layout from '../../../components/Layout';
import {
  WLBanner,
  WLContainer,
  WLH1,
  WLH2,
  WLH3,
  WLH4,
  WLH5,
  WLListItem,
  WLText,
  WLUnorderedList,
} from '../../../design-library';
import Diversity from './svgs/diversity.inline.svg';
import Education from './svgs/education.inline.svg';
import Growth from './svgs/growth.inline.svg';
import Medical from './svgs/medical.inline.svg';
import Promotion from './svgs/promotion.inline.svg';
import Reimbursement from './svgs/reimbursement.inline.svg';

interface ApplyPageProps extends PageProps<undefined> {}

const getHeading = () => <React.Fragment />;

const getDisclosures = () => {
  return (
    <>
      * For those who qualify
      <br />
      After filling out this form, you can expect a call from a Recruiting Sales
      Manager in your area.
    </>
  );
};

const getSubmitLabel = () => 'GET IN TOUCH';

const ApplyPage: React.FC<ApplyPageProps> = ({ location }) => {
  const campaign = new URLSearchParams(location.search).get('utm_campaign');
  return (
    <Layout
      seo={{
        title: 'Start a Career with WoodmenLife',
      }}
      currentPath={location.pathname}
    >
      <WLContainer width="WIDE" borderTopWidth="16px" borderTopColor="blue.300">
        <Box as="section" className="FlexRow FlexRow--0907 m-b-40">
          <Box className="hero-layout">
            <Box className="column-left">
              <WLH5 className="micro-caps">Sales Career</WLH5>
              <WLH1 className="header-caps">
                Find balance as a sales representative
              </WLH1>
              <WLText className="apply-paragraph">
                As a WoodmenLife® Sales Representative, you can make a
                difference every day--and have the opportunity to make a great
                income. Your pay directly reflects your results, not the numbers
                of hours you put in. You set your own schedule. With the option
                to work from home you have the ultimate career flexibility.
              </WLText>
            </Box>
            <Box className="column-right">
              <StaticImage
                src="./images/young-people-working_desktop.jpg"
                alt="Two people having a desk conversation"
                className="apply-image"
              />
            </Box>
          </Box>
        </Box>
        {campaign === 'recruiting_her2020' && (
          <Box className="TileGroup">
            <Box className="Tile Left Tile--copy">
              <Box className="Tile-content Tile-content--MarginSmall">
                <WLH1 className="HeroText--Bold">
                  Let's talk about whether we're <br></br>a good match.
                </WLH1>
              </Box>
            </Box>
            <Box className="Tile Right Right--wide Tile--image">
              <StaticImage
                className="Tile-content"
                src="./images/lcb-hero-recruiting_her2020.jpg"
                alt="business woman chatting on a video call with headphones and a laptop"
              />
            </Box>
          </Box>
        )}
        <Box as="section" className="FlexRow FlexRow--0907 m-b-40">
          <Box className="FlexColumn">
            <Box className="content-block desktop-readability">
              <WLH5 className="micro-caps">
                Woodmenlife sales representative
              </WLH5>
              <WLH2 className="header-caps">Job Description</WLH2>
              <WLText className="apply-paragraph">
                As a Sales Representative, you'll be responsible for developing
                new leads, communicating with and listening to members, who are
                our clients, understanding their financial needs, and ensuring a
                smooth sales process. You'll have the opportunity to build
                lasting relationships with members and help them reach their
                financial goals.
              </WLText>
            </Box>
            <Box className="content-block desktop-readability">
              <WLH5 className="micro-caps">The perks</WLH5>
              <WLH2 className="header-caps">Benefits</WLH2>
              <WLText className="apply-paragraph">
                Our goal is to help you live your best life both inside and
                outside of WoodmenLife. By providing an excellent benefits
                package if you qualify, you'll see our commitment to making sure
                you enjoy your work and have plenty of quality time for the ones
                you love.
              </WLText>
            </Box>
            <Box className="content-list-wrapper">
              <Box className="image-list-row">
                <Box className="list-item">
                  <Box className="image-box">
                    <Growth className="svg">
                      Your browser does not support SVGs
                    </Growth>
                  </Box>
                  <Box className="item-group">
                    <WLH3>Uncapped Commission + Bonuses</WLH3>
                    <WLText className="apply-paragraph">
                      With a 100% commission base, you have unlimited earning
                      potential and opportunities for multiple monetary bonuses
                      throughout the year. You are compensated for the work you
                      do, not the hours you put in.
                    </WLText>
                  </Box>
                </Box>

                <Box className="list-item">
                  <Box className="image-box">
                    <Medical className="svg" />
                  </Box>
                  <Box className="item-group">
                    <WLH3>Health and Wellness</WLH3>
                    <WLText className="apply-paragraph">
                      We offer a competitive benefits package for those who
                      qualify, designed to support you and your family,
                      including:
                    </WLText>
                    <WLUnorderedList className="apply-ul">
                      <WLListItem>401(k) with up to 5% match</WLListItem>
                      <WLListItem>Health insurance</WLListItem>
                      <WLListItem>Dental Insurance</WLListItem>
                      <WLListItem>Vision Insurance</WLListItem>
                      <WLListItem>Life Insurance</WLListItem>
                      <WLListItem>Disability Insurance</WLListItem>
                    </WLUnorderedList>
                  </Box>
                </Box>
              </Box>
              <Box className="image-list-row">
                <Box className="list-item">
                  <Box className="image-box">
                    <Education className="svg" />
                  </Box>
                  <Box className="item-group">
                    <WLH3>Training</WLH3>
                    <WLText className="apply-paragraph">
                      You'll receive training on the products we offer and sales
                      processes we use to help you build your independent
                      career. You also can connect with a Sales Manager to
                      support career growth.
                    </WLText>
                  </Box>
                </Box>

                <Box className="list-item">
                  <Box className="image-box">
                    <Reimbursement className="svg" />
                  </Box>
                  <Box className="item-group">
                    <WLH3>Support During Licensing</WLH3>
                    <WLText className="apply-paragraph">
                      You'll receive support and resources as you pursue the
                      necessary licensing to sell our products.
                    </WLText>
                  </Box>
                </Box>
              </Box>
              <Box className="image-list-row">
                <Box className="list-item">
                  <Box className="image-box">
                    <Promotion className="svg" />
                  </Box>
                  <Box className="item-group">
                    <WLH3>Growth Opportunities</WLH3>
                    <WLText className="apply-paragraph">
                      You'll have opportunities for advancement. Leaders are
                      promoted from within the WoodmenLife Sales Force. In fact,
                      our current and previous CEOs began their careers as Sales
                      Representatives.
                    </WLText>
                  </Box>
                </Box>
                <Box className="list-item">
                  <Box className="image-box">
                    <Diversity className="svg" />
                  </Box>
                  <Box className="item-group">
                    <WLH3>Diverse, Equitable, Inclusive</WLH3>
                    <WLText className="apply-paragraph">
                      WoodmenLife values our differences and respect what each
                      of us brings to the table. We are committed to diversity
                      and to providing opportunities for growth, leadership and
                      service to all. Individuals seeking a role with
                      WoodmenLife are considered without regard to race, color,
                      religion, national origin, age, sex, marital status,
                      ancestry, physical or mental disability, veteran status,
                      gender identity, or sexual orientation.
                    </WLText>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box as="section" className="applynow-form" id="form">
          <Box className="FlexColumn m-t-40">
            <Box className="form-container">
              <Box className="content-block">
                <WLH5 className="micro-caps">Learn More</WLH5>
                <WLH2 className="m-t-16">We'd Love to Get to Know You!</WLH2>
                <WLText className="apply-paragraph">
                  You could be a great fit. If being a Sales Representative
                  sounds like you, let's talk. Simply fill out the form below,
                  and someone from our Recruiting team will contact you on next
                  steps.
                </WLText>
              </Box>
              <Box>
                <LeadForm
                  mode={LeadFormMode.RECRUITING}
                  compact={false}
                  refPage="Life Career Balance"
                  subRef="New Apply Page"
                  heading={getHeading}
                  disclosures={getDisclosures}
                  submitLabel={getSubmitLabel}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <WLBanner
          title="Why WoodmenLife"
          primaryAction={{
            label: 'Learn More',
            href: 'https://www.woodmenlife.org/about/',
          }}
          darken
          media={{
            src: '//videos.ctfassets.net/s1t8xrunfio4/2haBOHw6inoth9RQxWaftc/6b25e9456a036298e455527b882ada5b/moments.mp4',
            format: 'video/mp4',
            alt: 'Some accessibility text',
            poster: './images/about-video-poster.jpg',
          }}
        >
          <WLText className="apply-paragraph">
            We've been helping to protect the financial future of families like
            yours, making a difference in hometowns across America and honoring
            our country since 1890. As a not-for-profit life insurance company,
            we put money back into the community. We're here when you need us
            most.
          </WLText>
        </WLBanner>
        <Box as="section" className="content-list-wrapper why-woodmen">
          <Box className="image-list-row">
            <Box className="list-item dark">
              <Box className="item-group">
                <WLH4>Sell a Product You Believe In</WLH4>
                <WLText className="apply-paragraph">
                  Selling today isn't about quick wins - it's about creating
                  value and building strong, lasting relationships. That's why
                  we're growing our sales team with people who want to help our
                  members make smart financial decisions. In fact,{' '}
                  <Link to="https://www.woodmenlife.org/about/" target="_blank">
                    ninety-eight percent of WoodmenLife members
                  </Link>{' '}
                  say they consider the service they receive from their Sales
                  Representative at the time of the sale to be "extremely" or
                  "very" helpful. Forbes has recognized us as one of{' '}
                  <Link
                    to="https://www.forbes.com/best-insurance-firms/##6d414b4f3659"
                    target="_blank"
                  >
                    America's best insurance companies
                  </Link>
                  , making it easier for you to tell the WoodmenLife story and
                  sell the products we offer.
                </WLText>
              </Box>
            </Box>
            <Box className="list-item">
              <Box className="item-group">
                <WLH4>Building a Diverse WoodmenLife</WLH4>
                <WLText className="apply-paragraph">
                  Everyone is part of our story. That's why we're committed to
                  ensuring we create a culture that celebrates our differences
                  and allows you to build a lifelong career. Through our
                  Diversity and Inclusion Advisory Committee, we work to create
                  meaningful change at WoodmenLife. The group works to
                  strengthen relationships, drive multicultural education, host
                  events, and create safe spaces where our people feel accepted
                  and empowered. When you become a Sales Representative for
                  WoodmenLife, you're joining a diverse team that shares your
                  passion for improving the lives of our members and our
                  communities.
                </WLText>
              </Box>
            </Box>
          </Box>

          <Box className="image-list-row">
            <Box className="list-item">
              <Box className="item-group">
                <WLH4>Support to Succeed</WLH4>
                <WLText className="apply-paragraph">
                  Get paid for the work you do, not the hours you put in. In
                  addition to your sales earnings, you'll have opportunities to
                  earn recognition and incentives. And because your family is
                  important, as a WoodmenLife Sales Representative, you can set
                  your own schedule and be there when they need you.
                </WLText>
              </Box>
            </Box>
            <Box className="list-item dark">
              <Box className="item-group">
                <WLH4>Make a Difference</WLH4>
                <WLText className="apply-paragraph">
                  Feel good about what you do by helping families prepare for
                  their financial futures and helping your community thrive
                  through WoodmenLife community programs.
                </WLText>
              </Box>
            </Box>
          </Box>
          <Box as="section" className="web-form-number">
            <WLText className="apply-paragraph">WEBLP96 - 7/21/2022</WLText>
          </Box>
        </Box>
        <Stack direction={{ base: 'column', lg: 'row' }} spacing={8}>
          <Box paddingX={{ base: 2, md: 0 }}></Box>
          <Box></Box>
        </Stack>
      </WLContainer>
    </Layout>
  );
};

export default ApplyPage;
